<template>
  <b-card class="p-1">
    
    <validation-observer
        ref="hitInformacoesRules"
        tag="form"
       >
        <b-form >
          <b-row class="align-items-center">
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <b-form-group label="Perfil" label-for="tipoPerfil">
                <b-form-input id="tipoPerfil" v-model="tipoPerfil" :readonly="true"/>
              </b-form-group>
          </b-col>  

            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  variant="primary"
                  :disabled="respostaApi"
                  @click="abrirModalAdicionarAbility()"
                 >
                     <span class="text-nowrap">Adicionar Funcionalidade</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

          
          
          <b-row>
              <b-col md="12">
                    <b-table
                    ref="abilitysTable"
                    :items="abilitys"
                    responsive
                    :fields="tableColumns"
                    primary-key="id"
                    :sort-by.sync="sortBy"
                    show-empty
                    empty-text="Sem resultados"
                    :sort-desc.sync="isSortDirDesc"
                  >
                    <template #cell(action)="data">
                      {{ data.item.action }}
                    </template>
                    <template #cell(subject)="data">
                      {{ data.item.subject }}
                    </template>
      
                    <template #cell(remover)="data">
                      <b-button variant="link" size="sm" class="float-right" @click="removerAbility(data.item.id)">
                        <feather-icon icon="Trash2Icon" size="18" class="text-danger" />
                      </b-button>
                    </template>
                  </b-table>

                

                <div class="mx-2 mb-2">
                  <b-row>

                  <b-col  cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start"/>
                  <b-col  cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalLista"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                      @input="mudarPagina"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>

                   </b-col>

                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-form>
      </validation-observer>

      <template>
        <b-modal id="modal-ability" ref="modalAbility" hide-footer centered no-close-on-backdrop size="md" title="Configuração de Funcionalidade">
          <validation-observer ref="registerForm" #default="{ invalid }">
            <b-form @submit.prevent="register">
              <v-select
                placeholder="Selecione a funcionalidade"
                v-model="selectedSubject"
                label="subjectModal"
                :options="subjectOptions"
                track-by="id"
                class="w-100"
              />
              <b-button variant="primary" class="w-100 mt-2" type="submit" :disabled="invalid || respostaApi">
                Salvar
                <b-spinner v-if="respostaApi" small label="Carregando" />
              </b-button>
            </b-form>
          </validation-observer>
        </b-modal>
      </template>
  </b-card>
</template>

<script>

import {
  BSpinner, BFormFile, BAlert, BIconEnvelope, BCard, BMedia, BMediaAside, BMediaBody, BFormInvalidFeedback, 
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BInputGroup, BInputGroupAppend, 
  BImg, BCardTitle, BCardText, BCardBody, BTable, BPagination, BModal, 
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import router from '@/router'

import { required, email } from '@validations'

import useJwt from '@/auth/jwt/useJwt'

import DisableAutocomplete from 'vue-disable-autocomplete'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { TheMask } from 'vue-the-mask'

// ERROS DOS CAMPOS
import { localize } from 'vee-validate'

localize({
  en: {
    messages: {
      // generic rule messages...
    },
    fields: {
      subject: {
        required: 'O nome da funcionalidade é obrigatório.',
      },
    },
  },
})


export default {
  components: {
  BSpinner, BFormFile, BAlert, BIconEnvelope, BCard, BMedia, BMediaAside, BMediaBody, BFormInvalidFeedback, 
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BInputGroup, BInputGroupAppend, 
  BImg, BCardTitle, BCardText, BCardBody, BTable, ValidationProvider,BPagination, ValidationObserver, BModal, vSelect,

    // autocomplete off
    DisableAutocomplete,
    TheMask,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      carregamentoDadosApi: true,
      perfilId: null,
      tipoPerfil: null,
      subject: null,
      abilitys: [],
      abilityModal: null,
      listaAbilitys: [],
      selectedSubject: null,
      subjectOptions: [],
      tableColumns: [
        { key: 'action', label: 'Ação', sortable: true },
        { key: 'subject', label: 'Funcionalidade' },
        { key: 'remover', label: '' },
      ],
      perPage: 10,
      currentPage: 1,
      totalLista: 0,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      respostaApi: false,
    }
  },
  mounted() {
    this.usuarioDados()
    this.carregarAbilitys()   
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    abrirModalAdicionarAbility() {
        this.$refs.modalAbility.show()
    },
    usuarioDados() {
      useJwt.perfilId({
        id: router.currentRoute.params.id,
      })
      .then(response => {
        const perfil = response.data
        this.perfilId = perfil.id;
        this.tipoPerfil = perfil.tipoPerfil;
        this.abilitys = perfil.ability;
      })
      .catch(error => {
        console.log( error.response );
        this.$swal({
          title: 'Erro!',
          text: error.response.data.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    carregarAbilitys() {
      this.carregamentoApi = true
      useJwt.abilityListarTotal()
        .then(response => {
          this.listaAbilitys = response.data

          this.subjectOptions = this.listaAbilitys.map(ability => ({ idSubject: ability.id, subjectModal: ability.subject, actionModal: ability.action }))
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.respostaApi = true;

          const selectedSubject = this.selectedSubject;
          const abilityId = selectedSubject ? selectedSubject.idSubject : null;

          // Chamada à API para salvar a funcionalidade
          useJwt.perfilAdicionarAbility({
            perfilId: this.perfilId,
            abilityId: abilityId,
          })
          .then(response => {
            this.$swal({
              title: 'Funcionalidade Cadastrada!',
              text: 'A funcionalidade foi cadastrada.',
              icon: 'success',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.isConfirmed) {
                this.$router.go();
              } else {
                this.$router.go();
              }
            });
          })
          .catch(error => {
            console.log(error.response);
            this.$swal({
              title: 'Erro!',
              text: 'Necessário selecionar uma funcionalidade.' || 'Ocorreu um erro ao cadastrar a funcionalidade.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
          })
          .finally(() => {
            this.respostaApi = false;
          });
        }
      });
    },
    mudarPagina() {
      this.carregarLista()
    },

    alterarPorPagina() {
      this.carregarLista()
    },
    removerAbility(abilityId) {
    this.$swal({
      title: 'Tem certeza?',
      text: 'Você está prestes a remover esta funcionalidade do perfil. Esta ação não pode ser revertida.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, remover',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-outline-secondary ml-1',
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        useJwt.perfilRemoverAbility({
          perfilId: this.perfilId,
          abilityId: abilityId
        })
        .then(response => {
          this.$swal({
            title: 'Funcionalidade Removida!',
            text: 'A funcionalidade foi removida do perfil.',
            icon: 'success',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(() => {
            this.usuarioDados(); 
          });
        })
        .catch(error => {
          this.$swal({
            title: 'Erro!',
            text: error.response.data.message || 'Ocorreu um erro ao remover a funcionalidade.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
      }
    })
  },
},
}
</script>

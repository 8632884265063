<template>

  <div>

    <vue-element-loading
      style="min-height: 500px;"
      :active="carregamentoApi"
      text="Carregando Perfis"
    >
      <img
        src="@/assets/images/logo/logo-loading.gif"
        width="40px"
      >
    </vue-element-loading>

    <b-card no-body class="mb-0">

      <div class="m-2">

      <b-row>

        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>Exibir</label>
          <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
            :clearable="false" class="per-page-selector d-inline-block mx-50" @input="alterarPorPagina"/>
        </b-col>

      </b-row>

    </div>


      <b-table ref="refUserListTable" class="position-relative" :items="perfis" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="Sem resultados" :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'perfil-editar', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              #{{ data.item.id }}
            </b-link>
          </b-media>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalLista"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="mudarPagina"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

// CARREGANDO DE PÁGINA PARA API
import VueElementLoading from 'vue-element-loading'

import useJwt from '@/auth/jwt/useJwt'
import PerfilConfiguracao from './PerfilConfiguracao.vue'

export default {
  components: {
    PerfilConfiguracao,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    VueElementLoading,
  },
  data() {
    return {
      perfis: [],
      tableColumns: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'tipoPerfil', label: 'Perfil', sortable: true },
      ],
      perPage: 10,
      currentPage: 1,
      totalLista: 0,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      refUserListTable: null,
      carregamentoApi: false,

    }
  },
  created() {
    this.carregarLista()
  },
  methods: {
    carregarLista() {
      this.carregamentoApi = true

      const paginaAtual = this.currentPage - 1 // DIMINUIR -1 DA PÁGINA ATUAL

      useJwt.perfilListar()
        .then(response => {
          this.perfis = response.data.content
          this.totalLista = response.data.totalElements
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },
    mudarPagina() {
      this.carregarLista()
    },
    alterarPorPagina() {
      this.carregarLista()
    },
  },

}

</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
